import React, { useEffect } from "react"
import Layout from "./layout"
import SEO from "./seo"
import { useStaticQuery, graphql } from "gatsby"
import Swiper from 'react-id-swiper'
import "react-id-swiper/lib/styles/css/swiper.css";
import $ from "jquery"
import { Tween, Timeline } from 'react-gsap';
import CSSPlugin from 'gsap/CSSPlugin';
import { Container, Row, Col } from 'react-bootstrap';
import { Controller, Scene } from 'react-scrollmagic';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { TimelineLite } from "gsap/all"
import ArticleYearSlider from './articleYearSlider'

const C = CSSPlugin;

const MultipleArticle = ({ pageContext }) => {
    useEffect(() => {
        $('html, body').animate({scrollTop:0}, 1000, function(){
            // $(".article-timeline-verticle").removeAttr("style");
        });
    }, []);
    const articleMilestones = pageContext.milestones.map((milestone) => {
        return <Col md={6} lg={4} key={milestone.article_slug}>
            <div className="milestone-item">
                <h3 className="milestone-item-title"><AniLink fade duration={2} to={"/" + milestone.article_slug}>{milestone.article_title}</AniLink></h3>
                <AniLink className="btn primary-btn" fade duration={2} to={"/" + milestone.article_slug}>EXPLORE ></AniLink>
            </div>
        </Col>
    });
    return (
        <Layout>
            <SEO title={pageContext.slug} />
            <Controller>
                <Scene
                duration={300}
                >
                    {(progress) => (
                        <>
                            <Timeline totalProgress={progress} paused
                                target={
                                    <div className="single-article-img" style={{backgroundImage:'url('+ pageContext.imagebig +')'}} alt={pageContext.title} />
                                }
                            >
                                <Tween
                                from={{ opacity: 1 }}
                                to={{ opacity: 0 }}
                                />
                            </Timeline>
                            <div className="single-article multiple-article-cover-wrap">
                                <div className="single-article-cover">
                                    <Container>
                                        <Row>
                                            <Col className="single-article-year-timeline" lg={{span: 4}} xl={{span: 5}}>
                                                <div className="article-timeline-verticle">
                                                    <ArticleYearSlider currentyear={pageContext.year} />
                                                    <div className="single-article-timeline"></div>
                                                </div>
                                            </Col>
                                            <Col className="single-article-wrap mt-150" lg={{span: 8, offset: 4}} xl={{span: 7, offset: 5}}>
                                                <div className="featured-article-sec">
                                                    <h1 className="page-title">
                                                        <AniLink fade duration={2} to={"/" + pageContext.featuredarticle[0].article_slug}>{pageContext.featuredarticle[0].article_title}</AniLink>
                                                    </h1>
                                                    <AniLink className="btn primary-btn" fade duration={2} to={"/" + pageContext.featuredarticle[0].article_slug}>EXPLORE ></AniLink>
                                                </div>
                                                <div className="article-milestones-sec">
                                                    <h3 className="article-milestones-sec-title">More milstones</h3>
                                                    <div className="article-milestones-list">
                                                        <Row>
                                                            {articleMilestones}
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </>
                    )}
                </Scene>
            </Controller>
        </Layout>
    )
}

export default MultipleArticle
